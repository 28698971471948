import { Mutation, Action, VuexModule, getModule, Module } from 'vuex-module-decorators'
import store from '@/store'
import { Consts } from '@/utils/consts'
import { Api } from '@/utils/api'
import Vue from 'vue'

interface AccountInfo {
  id: string
  name: string
  officeId: string
  isDam: boolean
  isStage: boolean
}

@Module({
  dynamic: true,
  store,
  name: 'auth',
  namespaced: true
})
class Auth extends VuexModule {
  private _token: string | null = null
  private _account: AccountInfo | null = null

  public get signed(): boolean {
    return this._token !== null && this._token !== ''
  }

  public get token(): string | null {
    return this._token
  }

  public get account(): AccountInfo | null {
    return this._account
  }

  public get isKasenka(): boolean {
    if (this.account === null) {
      return false
    } else {
      return this.account.officeId === '0'
    }
  }

  public get hasMlitStagePage(): boolean {
    if (this.account === null) {
      return false
    } else {
      return ['0', '2', '4', '9', '10', '11', '12'].includes(this.account.officeId)
    }
  }

  public get isStage(): boolean {
    if (this.account === null) {
      return false
    } else {
      return this.account.isStage
    }
  }

  public get isDam(): boolean {
    if (this.account === null) {
      return false
    } else {
      return this.account.isDam
    }
  }

  @Mutation
  setToken(token: string | null) {
    this._token = token
    if (token === null || token === '') {
      Vue.$cookies.remove(Consts.SESSION_KEY_TOKEN)
    } else {
      Vue.$cookies.set(Consts.SESSION_KEY_TOKEN, token)
    }
  }

  @Mutation
  setAccount(account: AccountInfo | null) {
    this._account = account
  }

  @Action
  public async login(params: { userId: string; password: string }): Promise<boolean> {
    console.log('login')
    const res = await Api.post(`/login`, {
      userId: params.userId,
      password: params.password
    })
    if (res === null || res.token === undefined || res.token === null || res.token === '') {
      return false
    }
    this.setToken(res.token)
    return true
  }

  @Action
  public async getAccountInfo(): Promise<void> {
    this.setAccount(null)
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    if (token === null || token === '') {
      return
    }
    const res = await Api.get(`/account/info`)
    if (res === null || res.id === undefined || res.id === '') {
      return
    }
    this.setAccount(res)
  }

  @Action
  public async hasSession(): Promise<boolean> {
    const token = Vue.$cookies.get(Consts.SESSION_KEY_TOKEN)
    if (token === null || token === '') {
      return false
    }
    const res = await Api.get(`/account/session`)
    if (res === null || res.result !== true || res.id === undefined || res.id === '') {
      return false
    } else {
      this.setToken(token)
      return true
    }
  }

  @Action
  public logout(): void {
    console.log('logout')
    this.setToken(null)
  }
}
export default getModule(Auth)
